<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                      <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Circular" vid="circular_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="circular_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('dae_subsidy.circular') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="Application.circular_id"
                                :options="circularList"
                                id="circular_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="division_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('org_pro_division.division') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="Application.division_id"
                                :options="divisionList"
                                id="division_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="district_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="Application.district_id"
                                :options="districtList"
                                id="district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                         <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Upazilla" vid="upazilla_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="upazilla_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('org_pro_upazilla.upazilla') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="Application.upazilla_id"
                                :options="upazilaList"
                                id="upazilla_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Union" vid="union_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="union_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('org_pro_union.union') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="Application.union_id"
                                :options="unionList"
                                id="union_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Ward" vid="ward_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="ward_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('org_pro.ward') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="Application.ward_id"
                                :options="wardList"
                                id="ward_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Village Name" vid='village' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="village"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{ $t('dae_subsidy.village_en')}}<span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  id="village"
                                  v-model="Application.village"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Village Name" vid='village_bn' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="village"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{ $t('dae_subsidy.village_bn')}}<span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  id="village_bn"
                                  v-model="Application.village_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                         <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Crop" vid="crop_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="crop_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('dae_subsidy.crop') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="Application.crop_id"
                                :options="cropList"
                                id="crop_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Instrument" vid="instrument_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="instrument_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('dae_subsidy.instrument_name') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="Application.instrument_id"
                                :options="instrumentList"
                                id="instrument_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                         <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Brand Name (En)" vid='brand_name' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="brand_name"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{ $t('dae_subsidy.brand_name_en')}}<span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  id="brand_name"
                                  v-model="Application.brand_name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                         <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Brand Name (Bn)" vid='brand_name_bn' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="brand_name_bn"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{ $t('dae_subsidy.brand_name_bn')}}<span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  id="brand_name_bn"
                                  v-model="Application.brand_name_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Title (En)" vid='title' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="title"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{ $t('dae_subsidy.title')}}<span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  id="title"
                                  v-model="Application.title"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Title (Bn)" vid='title_bn' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="title_bn"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{ $t('dae_subsidy.title_bn')}}<span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  id="title_bn"
                                  v-model="Application.title_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Model" vid='model' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="model"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{ $t('dae_subsidy.model')}}<span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  id="model"
                                  v-model="Application.model"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                         <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Supplier" vid="supplier_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="supplier_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('dae_subsidy.supplier_name') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="Application.supplier_id"
                                :options="supplierList"
                                id="supplier_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                         <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Land Type" vid="land_type" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="land_type"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('dae_subsidy.landType') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="Application.land_type"
                                :options="landTypeList"
                                id="land_type"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <ValidationProvider name="Application Type" vid="application_type" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="application_type"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('dae_subsidy.applicationType') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="Application.application_type"
                                :options="applicationList"
                                id="application_type"
                                @change="ApplicationTypeChange($event)"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                           <b-col xs="12" sm="12" md="8" lg="8" xl="8">
                            <ValidationProvider name="Comment" vid="comment" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="comment"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                      {{$t('budget.comment')}} <span class="text-danger">*</span>
                                  </template>
                                    <b-form-textarea
                                    id="description"
                                    v-model="Application.note"
                                    rows="1"
                                    max-rows="2"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="12" sm="12">
                           <template>
                              <div style="font-size:12px; background-color: #337982; padding:6px">
                                  <h5 class="text-white text-center"> {{ $t('dae_subsidy.farmer_information') }}</h5>
                              </div>
                           </template>
                          <b-row v-for="(detail,index) in Application.details" :key="index" style="border:2px solid #4D4442; margin-bottom:20px; padding-top:10px; margin-top:10px">
                              <b-col xl="3" md="3" lg="3" sm="4" class="mt-3">
                                <ValidationProvider name="Farmer Name (En)" :vid="`farmer_name${index}`" rules="required">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      :label-for="`farmer_name${index}`"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                        {{$t('dae_subsidy.farmer_name_en')}} <span class="text-danger">*</span>
                                    </template>
                                      <b-form-input
                                      id="farmer_name"
                                      v-model="detail.farmer_name"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col xl="3" md="3" lg="3" sm="4" class="mt-3">
                                <ValidationProvider name="Farmer Name (Bn)" :vid="`farmer_name_bn${index}`" rules="required">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      :label-for="`farmer_name_bn${index}`"
                                      slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                        {{$t('dae_subsidy.farmer_name_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="farmer_name_bn"
                                    v-model="detail.farmer_name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                                <b-col xl="3" md="3" lg="3" sm="4" class="mt-3">
                                  <ValidationProvider name="Father Name (En)" :vid="`father_name${index}`" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="12"
                                        :label-for="`father_name${index}`"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                          {{$t('dae_subsidy.father_name_en')}} <span class="text-danger">*</span>
                                      </template>
                                        <b-form-input
                                        id="father_name"
                                        v-model="detail.father_name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col xl="3" md="3" lg="3" sm="4" class="mt-3">
                                  <ValidationProvider name="Father Name (Bn)" :vid="`father_name_bn${index}`" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="12"
                                        :label-for="`father_name_bn${index}`"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                          {{$t('dae_subsidy.father_name_bn')}} <span class="text-danger">*</span>
                                      </template>
                                        <b-form-input
                                        id="father_name_bn"
                                        v-model="detail.father_name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col xl="4" md="4" lg="4" sm="4">
                                    <ValidationProvider name="Mobile Number" :vid="`mobile_no${index}`" rules="digits:11|required">
                                      <b-form-group
                                       type="number"
                                        label-cols-sm="12"
                                        class="row ml-1"
                                        :label-for="`mobile_no${index}`"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{$t('dae_subsidy.mobile_no')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        type="number"
                                        id="mobile_no"
                                        v-model="detail.mobile_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xl="4" md="4" lg="4" sm="4">
                                    <ValidationProvider name="NID" :vid="`nid${index}`" rules="required">
                                      <b-form-group
                                        label-cols-sm="12"
                                        class="row ml-1"
                                        :label-for="`nid${index}`"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{$t('dae_subsidy.nid')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        type="number"
                                        id="nid"
                                        v-model="detail.nid"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                              <b-col xl="3" lg="3" sm="4" class="mt-4" v-if="detailsButtonShow">
                                  <b-button v-show="index == Application.details.length-1" variant=" iq-bg-success" size="sm" class="mr-1" @click="add()"><i class="ri-add-line m-0"></i></b-button>
                                  <b-button v-show="index || ( !index && Application.details.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                              </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { applicationStore, applicationUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getApplicationData()
      this.Application = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      detailsButtonShow: false,
      Application: {
        circular_id: 0,
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        ward_id: 0,
        village: '',
        village_bn: '',
        crop_id: 0,
        instrument_id: 0,
        brand_name: '',
        brand_name_bn: '',
        title: '',
        title_bn: '',
        model: '',
        supplier_id: 0,
        application_type: 0,
        land_type: 0,
        note: '',
        details: [
            {
              farmer_name: '',
              farmer_name_bn: '',
              father_name: '',
              father_name_bn: '',
              mobile_no: '',
              nid: ''
            }
        ]
      },
      districtList: [],
      upazilaList: [],
      unionList: [],
      wardList: []
    }
  },
  computed: {
    saveBtnName: function () {
      return this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save')
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    cropList: function () {
      return this.$store.state.incentiveGrant.commonObj.cropList.filter(item => item.status === 1)
    },
    instrumentList: function () {
        const dataList = this.$store.state.incentiveGrant.commonObj.instrumentList.filter(item => item.status === 1)
        return dataList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    supplierList: function () {
        const dataList = this.$store.state.incentiveGrant.commonObj.supplierList.filter(item => item.status === 1)
        return dataList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    landTypeList: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Haor' : 'হাওর' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Non-haor' : 'সমতল' }
      ]
      return list
    },
    applicationList: function () {
      const dataList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Individual' : 'ব্যক্তিগত' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Group' : 'গ্রুপ' }
      ]
      return dataList
    },
    circularList: function () {
      const dataList = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1)
        return dataList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    }
  },
  watch: {
    'Application.division_id': function (newVal, oldVal) {
        this.districtList = this.getDistrictList(newVal)
    },
    'Application.district_id': function (newVal, oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
    },
    'Application.upazilla_id': function (newVal, oldVal) {
        this.unionList = this.getUnionList(newVal)
    },
    'Application.union_id': function (newVal, oldVal) {
        this.wardList = this.getWardList(newVal)
    }
  },
  methods: {
    ApplicationTypeChange (event) {
        if (event === 2) {
          this.detailsButtonShow = true
        } else {
          this.detailsButtonShow = false
        }
    },
    getApplicationData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
          result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${applicationUpdate}/${this.id}`, this.Application)
      } else {
          result = await RestApi.postData(incentiveGrantServiceBaseUrl, applicationStore, this.Application)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      this.loading = false
      if (result.success) {
          this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })

          this.$bvModal.hide('modal-4')
      } else {
          this.$refs.form.setErrors(result.errors)
      }
    },
    add () {
      const tampbpSetup = {
              farmer_name: '',
              farmer_name_bn: '',
              father_name: '',
              father_name_bn: '',
              mobile_no: '',
              nid: ''
      }
      const key1 = parseInt(this.Application.details.length - 1)
      const item = this.Application.details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
          if (item[key] === '' || item[key] === 0) {
              isEmpty = false
          }
      })
      if (isEmpty === true) {
        this.Application.details.push(tampbpSetup)
      }
    },
    remove (key) {
      this.Application.details.splice(key, 1)
    },
    getDistrictList (divisionId = null) {
        const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

        if (divisionId) {
            return districtList.filter(district => district.division_id === divisionId)
        }

        return districtList
    },
    getUpazilaList (districtId = null) {
        const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

        if (districtId) {
            return upazilaList.filter(upazila => upazila.district_id === districtId)
        }

        return upazilaList
    },
    getUnionList (upazilaId = null) {
        const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
        if (upazilaId) {
            return unionList.filter(union => union.upazilla_id === upazilaId)
        }

        return unionList
    },
    getWardList (unionId = null) {
        const wardList = this.$store.state.commonObj.wardList.filter(item => item.status === 0 && item.union_id === unionId)
        return wardList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text }
            }
        })
    }
  }
}
</script>
