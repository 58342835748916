
<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('dae_subsidy.application_management') }} {{ $t('globalTrans.search') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="Fiscal Year" vid="fiscal_year_id">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="fiscal_year_id"
                      slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('barc_config.fiscal_year') }}
                      </template>
                      <b-form-select
                      plain
                      v-model="search.fiscal_year_id"
                      :options="fiscalYearList"
                      id="fiscal_year_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="Season" vid="season_id">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="season_id"
                        slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{$t('dae_grant_allocation_distribution.season')}}
                    </template>
                    <b-form-select
                        plain
                        v-model="search.season_id"
                        :options="seasonList"
                        id="season_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="Circular" vid="circular_id">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="circular_id"
                        slot-scope="{ valid, errors }">
                        <template v-slot:label>
                        {{ $t('dae_subsidy.subsidy_circular') }}
                        </template>
                        <b-form-select
                        plain
                        v-model="search.circular_id"
                        :options="circularList"
                        id="circular_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="Division" vid="division_id">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="division_id"
                        slot-scope="{ valid, errors }">
                        <template v-slot:label>
                        {{ $t('org_pro_division.division') }}
                        </template>
                        <b-form-select
                        plain
                        v-model="search.division_id"
                        :options="divisionList"
                        id="division_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :disabled="authLocation.division_id != 0"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="District" vid="district_id">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="district_id"
                        slot-scope="{ valid, errors }">
                        <template v-slot:label>
                            {{ $t('org_pro_district.district') }}
                        </template>
                        <b-form-select
                            plain
                            v-model="search.district_id"
                            :options="districtList"
                            id="district_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :disabled="authLocation.district_id != 0"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="Upazilla" vid="upazilla_id">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="upazilla_id"
                        slot-scope="{ valid, errors }">
                        <template v-slot:label>
                            {{ $t('org_pro_upazilla.upazilla') }}
                        </template>
                        <b-form-select
                            plain
                            v-model="search.upazilla_id"
                            :options="upazilaList"
                            id="upazilla_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :disabled="authLocation.upazilla_id != 0"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="Status" vid="status">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="status"
                        slot-scope="{ valid, errors }">
                        <template v-slot:label>
                            {{$t('globalTrans.status')}}
                        </template>
                        <b-form-select
                            plain
                            v-model="search.status"
                            :options="subsidyStatusList"
                            id="status"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row>
          <b-col md="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('dae_subsidy.application_management_list') }}</h4>
              </template>
              <template v-slot:headerAction>
                <b-button class="btn_add_new">
                   <router-link :to="{ path: '/incentive-grant-service/farm-machinery/application-management/application-management-form'}" size="sm">
                       <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                   </router-link>
               </b-button>
              </template>
              <template v-slot:body>
                  <b-overlay :show="loadingState">
                      <b-row>
                          <b-col md="12" class="table-responsive">
                              <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                <template v-slot:cell(index)="data">
                                    {{ $n(data.index + pagination.slOffset) }}
                                </template>
                                <template v-slot:cell(farmer_name)="data">
                                   {{ $i18n.locale === 'bn' ? data.item.machinery_farmer.name_bn : data.item.machinery_farmer.name }}
                                </template>
                                <template v-slot:cell(farmer_mobile_no)="data">
                                    {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.machinery_farmer.mobile_no, { useGrouping: false }) }}
                                </template>
                                <template v-slot:cell(budget_amount)="data">
                                  {{ $n(data.item.amount) }}
                                </template>
                                <template v-slot:cell(code)="data">
                                  {{ $n(data.item.code, { useGrouping: false }) }}
                                </template>
                                <template v-slot:cell(status)="data">
                                  {{ getStatus(data.item.status) }}
                                </template>
                                <template v-slot:cell(application_type)="data">
                                  {{ getApplicationType(data.item.application_type) }}
                                </template>
                                <template v-slot:cell(action)="data">
                                  <!-- <b-button class="mr-1" title="View" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button> -->
                                  <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 size="sm" @click="details(data.item)">
                                      <i class="fas fa-eye"></i>
                                  </a>
                                </template>
                              </b-table>
                              <b-pagination
                                align="center"
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total-rows="pagination.totalRows"
                                @input="searchData"
                              />
                          </b-col>
                      </b-row>
                  </b-overlay>
              </template>
            </iq-card>
          </b-col>
        </b-row>
        <b-modal id="modal-5" size="xl" :title="$t('dae_subsidy.application_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :id="itemId" :key="itemId"></Details>
        </b-modal>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Form :id="itemId" :key="itemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import Details from '../SubsidyAppDetails'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { applocationList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
        search: {
          fiscal_year_id: 0,
          season_id: 0,
          circular_id: 0,
          division_id: 0,
          district_id: 0,
          upazilla_id: 0,
          status: 0,
          upazilla_id_arr: []
        },
        item: '',
        itemId: 0,
        districtList: [],
        upazilaList: [],
        unionList: [],
        wardList: [],
        upazillaIdList: [],
        circularList: [],
        authLocation: {
          division_id: 0,
          district_id: 0,
          upazilla_id: 0,
          upazilla_id_arr: []
        }
    }
  },
  computed: {
    currentLocale: function () {
        return this.$i18n.locale
    },
    seasonList: function () {
        return [
            { value: 1, text: this.$i18n.locale === 'en' ? 'Aus' : 'আউশ', text_en: 'Aus', text_bn: 'আউশ' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Amon' : 'আমন', text_en: 'Amon', text_bn: 'আমন' },
            { value: 3, text: this.$i18n.locale === 'en' ? 'Boro' : 'বোরো', text_en: 'Boro', text_bn: 'বোরো' }
        ]
    },
    localeCheck: function () {
      return this.$i18n.locale
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList
    },
    subsidyStatusList: function () {
      return this.$store.state.incentiveGrant.commonObj.subsidyStatusList.map(item => {
        if (this.localeCheck === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    formTitle () {
      return (this.itemId === 0) ? this.$t('dae_subsidy.application_management') + ' ' + this.$t('globalTrans.entry') : this.$t('dae_subsidy.application_management') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
        { label: this.$t('globalTrans.application_id'), class: 'text-center' },
        { label: this.$t('globalTrans.fiscal_year'), class: 'text-center' },
        { label: this.$t('dae_subsidy.circular_name'), class: 'text-center' },
        { label: this.$t('dae_subsidy.farmer_name'), class: 'text-center' },
        { label: this.$t('globalTrans.mobile'), class: 'text-center' },
        // { label: this.$t('dae_subsidy.instrument_name'), class: 'text-center' },
        { label: this.$t('dae_subsidy.application_type'), class: 'text-center' },
        { label: this.$t('globalTrans.status'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
            { key: 'index' },
            { key: 'code' },
            { key: 'fiscal_year_name_bn' },
            { key: 'circular_name_bn' },
            { key: 'farmer_name' },
            { key: 'farmer_mobile_no' },
            // { key: 'instructment_name_bn' },
            { key: 'application_type' },
            { key: 'status' },
            { key: 'action' }
        ]
      } else {
        keys = [
            { key: 'index' },
            { key: 'code' },
            { key: 'fiscal_year_name' },
            { key: 'circular_name' },
            { key: 'farmer_name' },
            { key: 'farmer_mobile_no' },
            // { key: 'instructment_name' },
            { key: 'application_type' },
            { key: 'status' },
            { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    landTypeList: function () {
      const list = [
        { value: 1, text: this.localeCheck === 'en' ? 'Haor' : 'হাওর' },
        { value: 2, text: this.localeCheck === 'en' ? 'Non-haor' : 'সমতল' }
      ]
      return list
    }
  },
  watch: {
    currentLocale: function () {
        this.circularList = this.getCircularList(this.search.fiscal_year_id)
    },
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    },
    'search.fiscal_year_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.circularList = []
            this.search.circular_id = 0
            this.circularList = this.getCircularList(newVal)
        }
    },
    'search.season_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.circularList = []
            this.search.circular_id = 0
            this.circularList = this.getCircularList(newVal)
        }
    },
    'search.circular_id': function (newVal, oldVal) {
        if (newVal !== oldVal && newVal > 0) {
            this.getInstrumentList(newVal)
        }
    }
  },
  created () {
    this.setAuthLocationInfo()
    this.loadData()
    this.getCurrentFiscalYear()
  },
  methods: {
    getCircularList (fiscalYearId) {
        const today = new Date().toISOString().slice(0, 10)
        if (parseInt(this.search.season_id)) {
            return this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.fiscal_year_id === parseInt(this.search.fiscal_year_id) && item.type === 1 && item.application_deadline >= today && item.season_id === parseInt(this.search.season_id))
        }
        return this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.fiscal_year_id === fiscalYearId && item.type === 1 && item.application_deadline >= today)
    },
    getCurrentFiscalYear () {
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList
        const currentDate = new Date()
        const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
        const yearPosition = month < 7 ? 5 : 0
        const yearStr = `${year}`
        fiscalYearList.forEach(element => {
            if (element.text_en.indexOf(yearStr) === yearPosition) {
                this.search.fiscal_year_id = element.value
            }
        })
    },
    searchData () {
      this.loadData()
    },
    forward (item) {
      this.itemId = item.id
    },
    details (item) {
      this.itemId = item.id
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const response = await RestApi.getData(incentiveGrantServiceBaseUrl, applocationList, params)
      if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
      } else {
        this.$store.dispatch('setList', [])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelatinalData (data) {
      const listData = data.map(item => {
        const fiscalYear = this.$store.state.commonObj.fiscalYearList.find(fy => fy.value === item.circular.fiscal_year_id)
        const circular = this.$store.state.incentiveGrant.commonObj.circularList.find(cr => cr.value === item.circular_id)
        const division = this.$store.state.commonObj.divisionList.find(div => div.value === item.machinery_farmer.division_id)
        const district = this.$store.state.commonObj.districtList.find(district => district.value === item.machinery_farmer.district_id)
        const upazilla = this.$store.state.commonObj.upazilaList.find(upazilla => upazilla.value === item.machinery_farmer.upazilla_id)
        const union = this.$store.state.commonObj.unionList.find(union => union.value === item.machinery_farmer.union_id)
        const ward = this.$store.state.commonObj.wardList.find(ward => ward.value === item.machinery_farmer.ward_id)
        // const instructment = this.$store.state.incentiveGrant.commonObj.instrumentList.find(instructment => instructment.value === item.instrument_id)
        const fiscalYearObj = { fiscal_year_name: fiscalYear !== undefined ? fiscalYear.text_en : '', fiscal_year_name_bn: fiscalYear !== undefined ? fiscalYear.text_bn : '' }
        const circularObj = { circular_name: circular !== undefined ? circular.text_en : '', circular_name_bn: circular !== undefined ? circular.text_bn : '' }
        const divisionObj = { division_name: division !== undefined ? division.text_en : '', division_name_bn: division !== undefined ? division.text_bn : '' }
        const districtObj = { district_name: district !== undefined ? district.text_en : '', district_name_bn: district !== undefined ? district.text_bn : '' }
        const upazillaObj = { upazilla_name: upazilla !== undefined ? upazilla.text_en : '', upazilla_name_bn: upazilla !== undefined ? upazilla.text_bn : '' }
        const unionObj = { union_name: union !== undefined ? union.text_en : '', union_name_bn: union !== undefined ? union.text_bn : '' }
        const wardObj = { ward_name: ward !== undefined ? ward.text_en : '', ward_name_bn: ward !== undefined ? ward.text_bn : '' }
        // const instrumentObj = { instructment_name: instructment !== undefined ? instructment.text_en : '', instructment_name_bn: instructment !== undefined ? instructment.text_bn : '' }
        return Object.assign({}, item, fiscalYearObj, circularObj, divisionObj, districtObj, upazillaObj, unionObj, wardObj)
      })
      return listData
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('budget.pending')
      } else if (status === 2) {
        return this.$t('budget.forward')
      } else if (status === 3) {
        return this.$t('budget.approve')
      } else if (status === 4) {
        return this.$t('budget.reject')
      } else if (status === 5) {
        return this.$t('dae_subsidy.distributed_new')
      }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    getApplicationType (type) {
      if (type === 1) {
        return this.localeCheck === 'en' ? 'Individual' : 'ব্যক্তিগত'
      } else {
        return this.localeCheck === 'en' ? 'Group' : 'গ্রুপ'
      }
    },
    getUpazillaIdList (officeId) {
      const office = this.$store.state.commonObj.officeList.find(item => item.value === officeId)
      if (typeof office !== 'undefined') {
        this.upazillaIdList.push(office.upazilla_id)
      }
      const childOfficeList = this.$store.state.commonObj.officeList.filter(item => item.parent_office_id === officeId)
      childOfficeList.forEach(childOffice => {
        this.getUpazillaIdList(childOffice.value)
      })
      return this.upazillaIdList.filter((value, index, self) => {
        return self.indexOf(value) === index
      })
    },
    setAuthLocationInfo () {
      let location = {
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        upazilla_id_arr: []
      }
      const authUser = this.$store.state.Auth.authUser
      if (!(authUser.role_id === 1 || authUser.is_org_admin)) {
        const office = this.$store.state.commonObj.officeList.find(item => item.value === authUser.office_id)
        if (typeof office !== 'undefined') {
          this.upazillaIdList = []
          const upazillaIdArr = this.getUpazillaIdList(office.value)
          location = {
            division_id: office.division_id,
            district_id: office.district_id,
            upazilla_id: office.upazilla_id,
            upazilla_id_arr: upazillaIdArr
          }
        }
      }
      this.search = Object.assign({}, this.search, location)
      this.authLocation = Object.assign({}, this.authLocation, location)
    }
  }
}
</script>
